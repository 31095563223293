<template>
  <div id="body">
    <!-- 头部 -->
    <div class="page-header">
      <!-- 导航 -->
      <TopBar class="navi" :activeIndex="'6'"/>
      <CBanner :bannerCont="bannerCont"/>
    </div>
    <div class="page-container">
      <p style="
      text-align: center;    
      font-size: 22px;    
      line-height: 50px;
      margin: 50px 0;"
      >
        人才招聘
      </p>
      <p>因业务发展需要，公司现向社会招聘各种人才。</p>
      <div class="txt-content">
        <h1>软件产品开发工程师1-2名</h1>
        <h2>岗位职责：</h2>
        <p>1. 负责公司产品的维护与测试；</p>
        <p>2. 责公司产品的售后服务，向用户提供产品使用的指导；</p>
        <p>3. 提供用户的反馈，负责对公司产品进行调试和升级。</p>
        <h2>岗位要求：</h2>
        <p>1. 要求必须学信网可查的硕士及以上学历；核科学与技术等相关专业；</p>
        <p>2. 熟悉Fortran/C++等语言，了解各项技术的相关标准，并严格按照标准进行开发；</p>
        <p>3. 对国内外知名的核反应堆相关程序有较深的了解，有较多使用经验者优先；</p>
        <p>4. 有核反应堆堆芯燃料管理计算经验者优先。</p>
        <p>5. 可接受出差者优先。</p>
      </div>
      <div class="txt-content">
        <h1>前端开发工程师1-2名</h1>
        <h2>岗位职责：</h2>
        <p>1.负责公司产品或项目Web前端的研发，并配合后端的开发；根据需求实现页面交互效果； </p>
        <p>2. 能够理解数据结构和算法设计，具有一定的软件工程意识。</p>
        <h2>岗位要求：</h2>
        <p>1. 要求必须学信网可查的本科及以上学历；计算机、软件工程等相关专业；</p>
        <p>2. 熟悉前端开发技术(HTML5、JS、JSON、XHTML、CSS3)，了解各项技术的相关标准，并严格按照标准进行开发；</p>
        <p>3. 有angularJs相关项目开发经验，掌握其原理，能独立开发常用组件；</p>
        <p>4. 熟练使用各种调试、抓包工具，能独立分析、解决和归纳问题；</p>
        <p>5. 熟悉各种Web客户端，尤其是主流移动端浏览器的开发模式和特性；</p>
        <p>6. 熟悉前端自动化和工程化、熟悉W3C标准，对表现与数据分离，Web语义化等有一定的理解；</p>
        <p>7. 有vue项目经验优先；</p>
        <p>8. 有APP开发经验者优先；</p>
        <p>9. 可接受出差者优先。</p>
      </div>
    </div>
    <div class="rpage-footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
// 导航
import TopBar from './common/TopBar.vue'
import CBanner from './common/content/CBanner.vue'
import Footer from './common/Footer.vue'

export default {
  name: "Profile",
  components: {
    'TopBar': TopBar,
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../assets/show-banner.png'),
        title: "人才招聘",
        txt: "",
      },
    }
  },
}
</script>

<style scoped>
div#body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-header {
  position: relative;
  top: 0;
  max-width: 1920px;
  margin: 0 auto;
}
/* 导航 */
.navi {
  position: absolute;
  width: 100%;
  z-index: 200;
}
div.page-container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  color: #323232;
  line-height: 2em;
}
div.page-container > div.txt-content {
  margin: 50px 0;
}
div.page-container > div.txt-content > h1 {
  font-size: 20px;
}
</style>